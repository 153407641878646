import NextImage from 'next/image';
import styled, { css } from 'styled-components';
import LinkButton from '../../LinkButton';

export const Container = styled.section<{
  $backgroundcolor?: string;
  $color?: string;
  $bgimage?: string;
  $center?: boolean;
  $columns?: '1' | '2';
  $ai?: boolean;
  $bg?: boolean;
  $withForm?: boolean;
}>`
  padding: calc(var(--section-padding) * 2) var(--site-padding);
  position: relative;
  background: ${({ $backgroundcolor }) => $backgroundcolor || 'var(--color-primary)'};
  color: ${({ $color }) => $color || 'var(--color-white)'};

  ${({ $withForm }) =>
    $withForm &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      gap: var(--spacing-large);
      align-items: center;
    `}

  ${({ $ai }) =>
    $ai &&
    css`
      background: transparent;
      position: relative;
    `}

  ${({ $bg }) =>
    $bg &&
    css`
      position: relative;
      padding: 0 var(--site-padding) var(--section-padding) var(--site-padding);
    `}
  
  @media (min-width: 1080px) {
    padding: var(--section-padding) var(--site-padding);
    grid-template-columns: ${({ $columns }) => ($columns === '2' ? '1fr 1fr' : '1fr')};
    ${({ $columns }) =>
      $columns === '2' &&
      css`
        justify-content: center;
      `}

    ${({ $bg }) =>
      $bg &&
      css`
        padding: calc(2 * var(--section-padding)) var(--site-padding);
      `}
    ${props =>
      props.$center &&
      css`
        place-items: center;
        text-align: center;
      `}
  }
  ${({ $bgimage }) =>
    $bgimage &&
    css`
      background-image: url(${$bgimage});
      background-size: cover;
      @media (min-width: 1080px) {
        background-position: center;
        background-position-x: 50%;
      }
    `}
`;

export const Button = styled(LinkButton)`
  padding: 20px var(--spacing-medium);
  line-height: 20px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
`;

export const DescriptionWrapper = styled.div`
  max-width: 35rem;
  z-index: 1;
`;

export const Title = styled.h1`
  font-size: var(--h1-size, 3rem);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-small);
  letter-spacing: -0.05em;
  line-height: 1.2;
`;

export const Kicker = styled.p<{ $color?: string }>`
  color: ${({ $color }) => ($color ? $color : 'var(--color-primary)')};
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: var(--spacing-small);
`;

export const ModuleFooter = styled.footer`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-medium);
  margin-top: var(--spacing-large);

  @media (min-width: 1080px) {
    justify-content: flex-start;
    flex-direction: row;
  }
`;

export const Wysiwyg = styled.div<{ $primaryColor?: string }>`
  font-size: var(--font-size-large);
  line-height: 25px;
  font-weight: normal;

  div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  ul {
    padding: 0;
    margin: 1rem 0;
    display: block;
  }

  li {
    margin-bottom: var(--spacing-small);
    list-style: none;
    position: relative;
    padding-left: 30px;
    display: flex;
  }

  li::before {
    position: absolute;
    line-height: 15px;
    left: 0;
    content: ${props =>
      `url('data:image/svg+xml, <svg width="17" height="22" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><path d="M976 154C1006 184 1006 234 976 266L398 846C368 876 318 876 286 846L24 582C-6 552-6 502 24 470 54 440 104 440 136 470L344 678 866 154C896 124 946 124 976 154Z" fill="${props.$primaryColor ? encodeURIComponent(props.$primaryColor) : 'current-color'}" /></svg>')`};
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  margin: calc(var(--section-padding) * -2) calc(var(--site-padding) * -1) var(--spacing-large);

  @media (min-width: 1080px) {
    height: 100%;
    margin: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    z-index: 0;
  }
`;

export const Image = styled(NextImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  @media (min-width: 1080px) {
    object-fit: contain;
    object-position: right bottom;
  }
`;
