import { useMediaQuery } from '@mnd-frontend/hooks';
import Image from 'next/image';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { TextBlockFragment, TextBlocksFragment } from '../../../wp-generated/types';
import type { ButtonVariants } from '../../Button/styling';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { liBeforeStyles, usePrimaryColor } from '../getPrimaryColorForCheckmark';

const Container = styled.section<{
  $background: string;
  $textColor: string;
  $grid: boolean;
  $isListOfTextBlocks: boolean;
  $includeImage: boolean;
  'data-bgcolor': (string | null)[] | null;
}>`
  width: 100%;
  background: ${({ $background }) => $background};
  color: ${({ $textColor }) => $textColor};
  padding: ${({ $grid, $includeImage }) =>
    $grid
      ? 'var(--spacing-2x-large) var(--site-padding)'
      : !$includeImage
        ? 'calc(var(--section-padding) * 1.5) calc((100vw - min(92vw, 675px)) / 2)'
        : 'var(--section-padding) var(--site-padding)'};

  [data-bgcolor='${props => props['data-bgcolor']}'] + & {
    padding-top: 0;
  }
`;
const ContentContainer = styled.div<{ $grid: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ $grid }) =>
    !$grid &&
    css`
      max-width: var(--site-width);
    `}
`;

const Title = styled.h2<{ $grid: boolean }>`
  max-width: 800px;
  font-weight: var(--font-weight-extra-bold);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: ${({ $grid }) => ($grid ? 'var(--spacing-large);' : 'var(--spacing-x-large);')};
`;

const TextBlocksContainer = styled.div<{ $grid: boolean; $numberOfTextBlocks: number }>`
  display: grid;
  gap: ${({ $grid }) => ($grid ? '26px' : '80px')};
  width: 100%;

  ${({ $grid, $numberOfTextBlocks }) =>
    $grid &&
    css`
      grid-template-columns: repeat(1, minmax(0, 1fr));

      ${
        $numberOfTextBlocks >= 4 && ($numberOfTextBlocks % 4 > 2 || $numberOfTextBlocks % 4 === 0)
          ? css`
            @media (min-width: 768px) {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (min-width: 1080px) {
              grid-template-columns: repeat(4, minmax(0, 1fr));
            }
          `
          : $numberOfTextBlocks >= 3
            ? css`
              @media (min-width: 768px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
              }

              @media (min-width: 1080px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
              }
            `
            : css`
              @media (min-width: 768px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
              }
            `
      }
    `}
`;

const TextBlock = styled.article<{ $grid: boolean; $isListOfTextBlocks: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $grid }) =>
    !$grid &&
    css`
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      &:nth-child(odd) {
        flex-direction: row;
      }
    `};

  ${({ $isListOfTextBlocks }) =>
    $isListOfTextBlocks
      ? css`
          @media (max-width: 1080px) {
            &&&& {
              flex-direction: column;
              gap: var(--spacing-medium);
              align-items: start;
            }
          }
        `
      : css`
          @media (max-width: 768px) {
            &&&& {
              flex-direction: column;
              gap: var(--spacing-medium);
              align-items: start;
            }
          }
        `}
`;

const TextBlockKicker = styled.div`
  color: var(--color-primary);
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-small);
`;

const TextBlockTitle = styled.h3<{ $grid: boolean }>`
  margin-top: 0;
  margin-bottom: var(--spacing-small);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  font-size: ${({ $grid }) => ($grid ? '25px' : 'clamp(1.5rem, 7vw, 2.75rem)')};
  line-height: ${({ $grid }) => ($grid ? '29px' : '1.2')};
  letter-spacing: -1px;
  word-break: break-word;
  hyphens: auto;
  letter-spacing: -0.03em;
`;

const TextBlockDescription = styled.div<{ $primaryColor: string; $includeImage: boolean }>`
  margin-bottom: var(--spacing-medium);

  ul {
    padding: 0;
    margin: 0;
    display: block;
  }

  li {
    margin-bottom: var(--spacing-small);
    list-style: none;
    position: relative;
    padding-left: 30px;
    display: flex;
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
  }

  ${({ $includeImage }) =>
    $includeImage &&
    css`
      & > *:not(:last-child) {
        margin-bottom: var(--spacing-medium);
      }
    `};

  ${liBeforeStyles}
`;

const TextBlockImage = styled(Image)<{ $grid: boolean; $isListOfTextBlocks: boolean }>`
  width: 49vw;
  max-width: ${({ $grid }) => (!$grid ? '49%' : '100%')};
  height: auto;
  object-fit: contain;
  ${({ $grid }) =>
    $grid &&
    css`
      margin-bottom: var(--spacing-medium);
    `}
  ${({ $isListOfTextBlocks }) =>
    $isListOfTextBlocks
      ? css`
          @media (max-width: 1080px) {
            max-width: 100%;
            width: 100%;
          }
        `
      : css`
          @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
          }
        `}
`;

const TextBlockText = styled.div<{
  $grid: boolean;
  $isListOfTextBlocks: boolean;
  $includeImage: boolean;
  $center?: boolean | null;
}>`
  display: flex;
  align-items: center;
  ${({ $center }) =>
    $center &&
    css`
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `}
  ${({ $isListOfTextBlocks, $grid, $includeImage }) =>
    $isListOfTextBlocks && !$grid && $includeImage
      ? css`
          @media (min-width: 1080px) {
            width: 41%;
          }
        `
      : !$grid && $includeImage
        ? css`
            @media (min-width: 768px) {
              width: 41%;
            }
          `
        : css`
            width: 100%;
          `}
  ${({ $includeImage }) =>
    !$includeImage &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 675px;
      text-align: center;
    `}
`;

const TextBlockButton = styled(LinkButton)`
  max-width: fit-content;
  line-height: 1.2;
`;

const LinkArrow = styled.span`
  display: inline;
  margin-left: 7px;

  > svg {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    transition: transform var(--anim-speed) var(--easing);
  }
`;

const TextBlockLink = styled(Link)<{ $textColor: string }>`
  display: flex;
  width: fit-content;
  align-items: center;

  color: ${({ $textColor }) =>
    $textColor === 'var(--text-color)' ? 'var(--link-color)' : $textColor};

  &:hover svg {
    transform: translateX(2px);
  }
`;

const TextBlockLinkText = styled.div`
  font-weight: var(--font-weight-semi-bold);
  display: inline;
  vertical-align: middle;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-small);
`;

const BottomButton = styled(LinkButton)`
  padding: 9px 17px;
  margin-top: var(--spacing-x-large);
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const LinkArrowComponent = () => {
  return (
    <LinkArrow>
      <svg viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M712.1 578.2L99 578.2C55.8 578.2 20.8 543.2 20.8 500 20.8 456.8 55.8 421.7 99 421.7L712.1 421.7 563.1 272.8C532.6 242.2 532.6 192.7 563.1 162.2 593.7 131.6 643.2 131.6 673.8 162.2L956.3 444.7C986.9 475.2 986.9 524.8 956.3 555.3L673.8 837.9C643.2 868.4 593.7 868.4 563.1 837.9 532.6 807.3 532.6 757.8 563.1 727.2L712.1 578.2Z"
          stroke="currentColor"
          fill="currentColor"
        />
      </svg>
    </LinkArrow>
  );
};

const LinkTargetComponent = () => {
  return (
    <LinkArrow>
      <svg viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M582 68C582 32 612 2 648 2H936C942 0 950 0 956 2 966 4 976 10 984 18 996 32 1004 48 1002 66V352C1002 388 972 418 936 418 900 418 870 388 870 352V226L450 642C424 666 382 664 358 638 336 612 336 574 358 550L772 134H644C608 132 582 104 582 68ZM170 278C154 278 140 292 140 308V834C140 850 154 864 170 864H696C712 864 726 850 726 834V548C726 512 756 482 792 482 828 482 858 512 858 548L858 548V836C858 926 784 1000 694 1000H164C74 1000 0 926 0 836V308C0 218 74 144 164 144H452C488 144 518 174 518 210 518 246 488 276 452 276L170 278Z"
          stroke="currentColor"
          fill="currentColor"
        />
      </svg>
    </LinkArrow>
  );
};

const isTextBlockListItem = (
  textBlock: NonNullable<TextBlocksFragment['textBlocks']>[0] | TextBlockFragment,
): textBlock is NonNullable<TextBlocksFragment['textBlocks']>[0] | null => {
  return (textBlock as NonNullable<TextBlocksFragment['textBlocks']>[0])?.linkStyle !== undefined;
};

const TextBlockLinkComponent = ({
  textBlock,
  buttonColor,
  textColor,
}: {
  textBlock: NonNullable<TextBlocksFragment['textBlocks']>[0] | TextBlockFragment | null;
  buttonColor: ButtonVariants;
  textColor: string;
}) => {
  return isTextBlockListItem(textBlock) &&
    textBlock?.linkStyle?.includes('link--arrow') &&
    textBlock?.link?.url ? (
    <div>
      <TextBlockLink
        href={formatUrl(textBlock?.link?.url)}
        target={textBlock?.link?.target || ''}
        $textColor={textColor}
        onClick={ctaClickHandler('text_block')}
        prefetch={false}
      >
        <TextBlockLinkText>
          {textBlock?.link?.title}
          <LinkArrowComponent />
        </TextBlockLinkText>
      </TextBlockLink>
    </div>
  ) : isTextBlockListItem(textBlock) &&
    textBlock?.linkStyle?.includes('button button--ghost') &&
    textBlock?.link?.url ? (
    <TextBlockButton
      href={formatUrl(textBlock?.link?.url)}
      target={textBlock?.link?.target || ''}
      variant={buttonColor === 'primary' ? 'secondary' : buttonColor}
      onClick={ctaClickHandler('text_block')}
      prefetch={false}
    >
      {textBlock?.link?.title}
    </TextBlockButton>
  ) : isTextBlockListItem(textBlock) && textBlock?.link?.url ? (
    <TextBlockButton
      href={formatUrl(textBlock?.link?.url)}
      target={textBlock?.link?.target || ''}
      variant={buttonColor}
      onClick={ctaClickHandler('text_block')}
      prefetch={false}
    >
      {textBlock?.link?.title}
    </TextBlockButton>
  ) : !isTextBlockListItem(textBlock) ? (
    textBlock?.links?.map(
      (link, index) =>
        link?.link?.url &&
        (link.style === 'link--arrow' ? (
          <div key={index}>
            <TextBlockLink
              href={formatUrl(link?.link?.url)}
              target={link?.link?.target || ''}
              $textColor={textColor}
              onClick={ctaClickHandler('text_block')}
              prefetch={false}
            >
              <TextBlockLinkText>
                {link?.link?.title}
                {link?.link?.target ? <LinkTargetComponent /> : <LinkArrowComponent />}
              </TextBlockLinkText>
            </TextBlockLink>
          </div>
        ) : link.style === 'button button--ghost' ? (
          <TextBlockButton
            key={index}
            href={formatUrl(link?.link?.url)}
            target={link?.link?.target || ''}
            variant={buttonColor === 'primary' ? 'secondary' : buttonColor}
            onClick={ctaClickHandler('text_block')}
            prefetch={false}
          >
            {link?.link?.title}
          </TextBlockButton>
        ) : (
          <TextBlockButton
            key={index}
            href={formatUrl(link?.link?.url)}
            target={link?.link?.target || ''}
            variant={buttonColor}
            onClick={ctaClickHandler('text_block')}
            prefetch={false}
          >
            {link?.link?.title}
          </TextBlockButton>
        )),
    )
  ) : (
    <div />
  );
};

const getWidthAndHeight = (displayTextBlockAsColumn: boolean, isListOfTextBlocks: boolean) => {
  if (isListOfTextBlocks) {
    if (displayTextBlockAsColumn) {
      return {
        width: 380,
        height: 210,
      };
    } else
      return {
        width: 580,
        height: 165,
      };
  }
  if (displayTextBlockAsColumn) {
    return {
      width: 380,
      height: 250,
    };
  } else
    return {
      width: 480,
      height: 400,
    };
};

const TextBlockComponent = ({
  displayAsGrid,
  textBlock,
  isListOfTextBlocks,
  buttonColor,
  bgColor,
  includeImage,
  imageToRight,
}: {
  displayAsGrid: boolean;
  textBlock: NonNullable<TextBlocksFragment['textBlocks']>[0] | TextBlockFragment | null;
  isListOfTextBlocks: boolean;
  buttonColor: ButtonVariants;
  bgColor: (string | null)[] | null;
  includeImage: boolean;
  imageToRight: boolean;
}) => {
  const containerRefAndPrimaryColor = usePrimaryColor();
  const displayListItemTextBlockAsColumn = useMediaQuery('@media (max-width: 1079px)');
  const displayTextBlockAsColumn = useMediaQuery('@media (max-width: 767px)');

  const imageShouldBeRenderedBeforeText =
    !imageToRight ||
    displayAsGrid ||
    (displayTextBlockAsColumn && !isListOfTextBlocks) ||
    (displayListItemTextBlockAsColumn && isListOfTextBlocks);
  const center = textBlock && 'template' in textBlock && textBlock.template?.[0] === 'center';
  return (
    <TextBlock
      $grid={displayAsGrid}
      $isListOfTextBlocks={isListOfTextBlocks}
      ref={containerRefAndPrimaryColor.ref}
    >
      {includeImage && imageShouldBeRenderedBeforeText && textBlock?.image?.node.mediaItemUrl && (
        <TextBlockImage
          $grid={displayAsGrid}
          src={textBlock.image.node.mediaItemUrl}
          $isListOfTextBlocks={isListOfTextBlocks}
          alt=""
          role="presentation"
          width={
            textBlock.image.node.mediaDetails?.width ??
            getWidthAndHeight(displayTextBlockAsColumn, isListOfTextBlocks).width
          }
          height={
            textBlock.image.node.mediaDetails?.height ??
            getWidthAndHeight(displayTextBlockAsColumn, isListOfTextBlocks).height
          }
          sizes={textBlock?.image?.node.sizes || undefined}
          loading="lazy"
        />
      )}
      <TextBlockText
        $grid={displayAsGrid}
        $isListOfTextBlocks={isListOfTextBlocks}
        $includeImage={includeImage}
        $center={center}
      >
        <div>
          {textBlock?.kicker && (
            <TextBlockKicker dangerouslySetInnerHTML={{ __html: textBlock?.kicker || '' }} />
          )}
          {textBlock?.title && (
            <TextBlockTitle
              $grid={displayAsGrid}
              dangerouslySetInnerHTML={{ __html: textBlock?.title || '' }}
            />
          )}
          <TextBlockDescription
            dangerouslySetInnerHTML={{ __html: textBlock?.description || '' }}
            $primaryColor={containerRefAndPrimaryColor.color}
            $includeImage={includeImage}
          />
          <TextBlockLinkComponent
            textBlock={textBlock}
            buttonColor={buttonColor}
            textColor={chooseLightOrDarkTextColor(bgColor)}
          />
        </div>
      </TextBlockText>
      {imageToRight &&
        includeImage &&
        !imageShouldBeRenderedBeforeText &&
        textBlock?.image?.node.mediaItemUrl && (
          <TextBlockImage
            $grid={displayAsGrid}
            src={textBlock.image.node.mediaItemUrl}
            $isListOfTextBlocks={isListOfTextBlocks}
            alt=""
            role="presentation"
            width={textBlock.image.node.mediaDetails?.width ?? 558}
            height={
              textBlock.image.node.mediaDetails?.height ?? (displayTextBlockAsColumn ? 406 : 384)
            }
            sizes={textBlock?.image?.node.sizes || undefined}
            loading="lazy"
          />
        )}
    </TextBlock>
  );
};

const TextBlocksModule = ({
  moduleData,
}: {
  moduleData: TextBlocksFragment | TextBlockFragment;
}) => {
  const isListOfTextBlocks = (
    mod: TextBlocksFragment | TextBlockFragment,
  ): mod is TextBlocksFragment => {
    return (mod as TextBlocksFragment).textBlocks !== undefined;
  };

  const buttonColor = chooseButtonColorBasedOnBackground(moduleData.bgColor);
  const displayAsGrid = moduleData.template?.includes('columns') || false;
  const includeImage = !moduleData.template?.includes('center') || false;
  const imageToRight = !moduleData.template?.includes('template-2') || false;
  const isMobile = useMediaQuery('@media (max-width: 767px)');
  return (
    <Container
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
      $textColor={chooseLightOrDarkTextColor(moduleData.bgColor)}
      $grid={displayAsGrid}
      $isListOfTextBlocks={isListOfTextBlocks(moduleData)}
      data-bgcolor={moduleData.bgColor}
      $includeImage={includeImage}
    >
      <ContentContainer $grid={displayAsGrid}>
        {moduleData.title && isListOfTextBlocks(moduleData) && (
          <Title $grid={displayAsGrid}>{moduleData.title}</Title>
        )}
        <TextBlocksContainer
          $grid={displayAsGrid}
          $numberOfTextBlocks={
            isListOfTextBlocks(moduleData) ? (moduleData.textBlocks?.length ?? 0) : 0
          }
        >
          {isListOfTextBlocks(moduleData) ? (
            moduleData.textBlocks?.map((textBlock, index) => {
              return (
                <TextBlockComponent
                  key={index}
                  displayAsGrid={displayAsGrid}
                  textBlock={textBlock}
                  isListOfTextBlocks={isListOfTextBlocks(moduleData)}
                  buttonColor={buttonColor}
                  bgColor={moduleData.bgColor}
                  includeImage={includeImage}
                  imageToRight={imageToRight}
                />
              );
            })
          ) : (
            <TextBlockComponent
              displayAsGrid={displayAsGrid}
              textBlock={moduleData}
              isListOfTextBlocks={isListOfTextBlocks(moduleData)}
              buttonColor={buttonColor}
              bgColor={moduleData.bgColor}
              includeImage={includeImage}
              imageToRight={imageToRight}
            />
          )}
        </TextBlocksContainer>
        {isListOfTextBlocks(moduleData) && (
          <ButtonWrapper>
            {moduleData.links?.map(
              (link, index) =>
                link?.link?.url && (
                  <BottomButton
                    key={index}
                    href={formatUrl(link?.link?.url)}
                    target={link?.link?.target || ''}
                    variant={buttonColor === 'primary' ? 'primary' : 'purple'}
                    fullWidth={isMobile}
                    onClick={ctaClickHandler('text_block')}
                    prefetch={false}
                  >
                    {link?.link?.title}
                  </BottomButton>
                ),
            )}
          </ButtonWrapper>
        )}
      </ContentContainer>
    </Container>
  );
};

export default TextBlocksModule;
