import NextImage from 'next/image';
import styled from 'styled-components';
import type { PageFragment } from '../../../wp-generated/types';
import { getColorForCheckmark } from '../getModuleColors';
import { Container, Kicker, Title, Wysiwyg } from './CommonStyles';

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  margin: calc(var(--section-padding, 6rem) * -1) calc(var(--site-padding) * -1)
    var(--section-padding);
  height: 0;
  z-index: -1;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  video {
    object-position: left;
  }
`;

const Content = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
`;

const Image = styled(NextImage)`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
`;

const HeroAi = ({ data }: { data: NonNullable<PageFragment['hero']>['heroAi'] }) => {
  const mediaType = data?.media?.includes('image') ? 'image' : 'video';

  return (
    <Container $ai data-type="ai">
      {mediaType === 'image' && data?.image?.node?.mediaItemUrl && (
        <StickyContainer>
          <Image
            src={data.image.node.mediaItemUrl}
            alt=""
            fill
            sizes={data.image.node.sizes || undefined}
          />
        </StickyContainer>
      )}
      {mediaType === 'video' && data?.video?.node?.mediaItemUrl && (
        <StickyContainer>
          <video autoPlay loop muted playsInline src={data.video.node.mediaItemUrl} />
        </StickyContainer>
      )}
      <Content>
        {data?.kicker && <Kicker $color={getColorForCheckmark(['white'])}>{data.kicker}</Kicker>}
        <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        <Wysiwyg
          dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}
          $primaryColor={getColorForCheckmark(['white'])}
        />
      </Content>
    </Container>
  );
};

export default HeroAi;
