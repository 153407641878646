import Image from 'next/image';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { selectContentClickHandler } from '../../../lib/tracker';
import type { EmployeesFragment } from '../../../wp-generated/types';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{
  $background: string;
  $textColor: string;
  $numberOfEmployees: number;
}>`
  background: ${({ $background }) => $background};
  color: ${({ $textColor }) => $textColor};
  padding: ${({ $numberOfEmployees }) =>
    $numberOfEmployees > 1
      ? 'var(--spacing-2x-large) 0'
      : 'var(--spacing-2x-large) calc((100vw - min(92vw, 800px)) / 2)'};
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: var(--site-width);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: var(--font-weight-extra-bold);
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
`;

const EmployeesContainer = styled.div<{ $grid: boolean; $numberOfEmployees: number }>`
  display: grid;
  width: 100%;

  ${({ $grid }) =>
    $grid &&
    css`
      gap: 3rem var(--spacing-medium);
    `};

  ${({ $grid, $numberOfEmployees }) =>
    $grid &&
    css`
      ${
        $numberOfEmployees >= 4 && $numberOfEmployees % 4 !== 1
          ? css`
            @media (min-width: 768px) {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (min-width: 1080px) {
              grid-template-columns: repeat(4, minmax(0, 1fr));
            }
          `
          : $numberOfEmployees >= 4
            ? css`
              @media (min-width: 768px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
              }

              @media (min-width: 1080px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
              }
            `
            : css`
              max-width: 800px;
              @media (min-width: 768px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
              }
            `
      }
    `}
`;

const Employee = styled.article`
  display: flex;
  flex-direction: column;
  figure {
    width: 100%;
    margin: 0;
  }
`;

const ImageComponent = styled(Image)`
  width: 100%;
  height: auto;
  margin-bottom: var(--spacing-medium);
  object-fit: cover;
`;

const Name = styled.h3`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: var(--spacing-small);
  line-height: 1.2;
  letter-spacing: -0.02em;
`;

const JobTitle = styled.p`
  margin-bottom: 4px;
`;

const Email = styled.a`
  color: var(--link-color);
`;

const EmployeesModule = ({ moduleData }: { moduleData: EmployeesFragment }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container
      ref={containerRef}
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
      $textColor={chooseLightOrDarkTextColor(moduleData.bgColor)}
      $numberOfEmployees={moduleData.data?.length || 0}
    >
      <ContentContainer>
        <Title>{moduleData.title}</Title>
        <EmployeesContainer $grid $numberOfEmployees={moduleData.data?.length || 0}>
          {moduleData.data?.map((employee, index) => (
            <Employee key={index}>
              <figure>
                <ImageComponent
                  width={960}
                  height={540}
                  src={
                    employee?.image || 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                  }
                  alt=""
                />
                <figcaption>
                  <Name>{employee?.name || ''}</Name>
                  <JobTitle>{employee?.jobTitle || ''}</JobTitle>
                  {employee?.email && (
                    <Email
                      href={`mailto:${employee?.email}`}
                      onClick={selectContentClickHandler(`employee-${index + 1}`, 'employee')}
                    >
                      {employee?.email || ''}
                    </Email>
                  )}
                </figcaption>
              </figure>
            </Employee>
          ))}
        </EmployeesContainer>
      </ContentContainer>
    </Container>
  );
};

export default EmployeesModule;
