import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import type { PageFragment } from '../../../wp-generated/types';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  chooseSecondaryButtonColorBasedOnPrimaryButton,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import {
  Button,
  Container,
  DescriptionWrapper,
  Image,
  ImageWrapper,
  Kicker,
  ModuleFooter,
  Title,
  Wysiwyg,
} from './CommonStyles';

const Hero = ({ data }: { data: NonNullable<PageFragment['hero']>['hero'] }) => {
  const buttonColor = chooseButtonColorBasedOnBackground(data?.bgColor);
  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $columns={data?.image?.node?.mediaItemUrl ? '2' : '1'}
      data-type="hero"
    >
      {data?.image?.node?.mediaItemUrl && (
        <ImageWrapper>
          <Image
            src={data.image.node.mediaItemUrl}
            alt=""
            width={data.image.node.mediaDetails?.width ?? 845}
            height={data.image.node.mediaDetails?.height ?? 806}
            sizes={data?.image?.node?.sizes || undefined}
          />
        </ImageWrapper>
      )}
      <DescriptionWrapper>
        <header>
          {data?.kicker && <Kicker>{data.kicker}</Kicker>}
          <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        </header>
        <Wysiwyg dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
      </DescriptionWrapper>
      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <Button
              key={i}
              href={formatUrl(link?.link?.url)}
              variant={
                i % 2 === 1
                  ? chooseSecondaryButtonColorBasedOnPrimaryButton(buttonColor)
                  : buttonColor
              }
              onClick={ctaClickHandler('hero')}
            >
              {link?.link?.title}
            </Button>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default Hero;
